import { baseUrl } from 'constants/appConstants';

export const androidApp = 'https://play.google.com/store/apps/details?id=ai.aloft.airaware';
export const iOSApp = 'https://apps.apple.com/us/app/aloft-air-aware/id6471439866';
export const advisoriesLearnMoreLink = `${baseUrl}/further-information-for-map-layers-advisory-details/`;
export const laancLink = 'https://air.aloft.ai';
export const visitFAA = 'https://www.faa.gov/uas/programs_partnerships/data_exchange/';
export const contactUsLink = 'mailto:support@aloft.ai';
export const aboutPilotInstituteLink = `https://www.aloft.ai/about-pilot-institute/`;
export const aboutAloftLink = 'https://www.aloft.ai/about-aloft/';
export const termsLink = `${baseUrl}/airaware/terms`;
export const registerDroneLink = 'https://faadronezone.faa.gov';
export const freeCoursesLink = 'https://pilotinstitute.com/courses/drones/';
export const aboutB4UFLYLink = 'https://www.aloft.ai/about-b4ufly/';
export const safetyGuidelinesLink = 'https://www.aloft.ai/b4ufly-safety-guidelines/';
