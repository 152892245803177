import { androidApp, iOSApp } from 'constants/externalLinks';

const MobileDownloadPrompt = () => {
	const { userAgent } = window.navigator;

	if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
		window.location.assign(iOSApp);
	}

	if (userAgent.includes('Android')) {
		window.location.assign(androidApp);
	}

	return null;
};

export default MobileDownloadPrompt;
