import ReactMapboxGl from 'react-mapbox-gl';
import { MAPBOX_KEY, SANCTUM_TOKEN } from 'constants/envVars';
import { $brandColor } from 'styles/colors';

export const Mapbox = ReactMapboxGl({
	accessToken: MAPBOX_KEY,
	doubleClickZoom: false,
	dragRotate: true,
	attributionControl: false,
	transformRequest: (url, resourceType) => {
		if ((resourceType === 'Source' || resourceType === 'Tile') && url.startsWith('https://maps.')) {
			return {
				url: url,
				headers: {
					Authorization: 'Bearer ' + SANCTUM_TOKEN,
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			};
		}
	}
});

export const mapDrawStyles = [
	// ACTIVE (being drawn)
	// line stroke
	{
		id: 'gl-draw-line',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': $brandColor,
			// 'line-dasharray': [0.2, 2],
			'line-width': 6
		}
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'fill-color': $brandColor,
			'fill-outline-color': $brandColor,
			'fill-opacity': 0.8
		}
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon']],
		paint: {
			'line-color': '#fff',
			'line-opacity': 1,
			'line-width': 6
		},
		layout: {
			'line-join': 'round',
			'line-cap': 'round'
		}
	},
	// vertex point halos
	{
		id: 'gl-draw-polygon-and-line-vertex-halo-active',
		type: 'symbol',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'icon-opacity': 1
		},
		layout: {
			'icon-image': 'hospital-15',
			'icon-size': 1
		}
	},
	// vertex points
	{
		id: '',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 10, // size polygon draggable points
			'circle-color': '#fff', // color polygon draggable points
			'circle-opacity': 0.75 // opacity polygon draggable points
		}
	},
	// midpoints
	{
		id: 'circle-midpoint',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 5, // size polygon draggable points
			'circle-color': '#000', // color polygon draggable points
			'circle-opacity': 0.25 // opacity polygon draggable points
		}
	}
];

export const defaultMapStyleId = 'ac-default';
export const defaultMapCenter = [-95.9325, 41.25603]; //Center of USA
export const airspaceClassLayerName = 'faa_class_airspace';
export const notifyAndFlyLayerId = 'notify-and-fly';
export const notifyAndFlySourceId = 'notify_and_fly';
