export const notifyRecreational = 'recreational';
export const notifyCommercial = 'commercial';
export const notifyGovernment = 'government';
export const notifyFirstResponder = 'first_responder';
export const flightTypeOptions = [
	{ value: notifyRecreational, label: 'Recreational' },
	{ value: notifyCommercial, label: 'Commercial' },
	{ value: notifyGovernment, label: 'Government' },
	{ value: notifyFirstResponder, label: 'First Responder' }
];
export const notifyAreaFeatureId = 'notify-area-feature-id';
// export const defaultNotifyType = 'recreational';
export const defaultFormFields = {
	type: 'recreational',
	ceiling: 400,
	start_time: null,
	duration: 60
};
export const notifyCreateSuccessAlert = {
	message: 'Your flight was successfully filed.',
	type: 'success',
	autoDismiss: true
};
