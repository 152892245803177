import { useEffect, useState } from 'react';
import MapContainer from 'components/map/MapContainer';
import RightPanelContainer from 'components/rightPanel/RightPanelContainer';
import SplitPane from 'react-split-pane';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from 'components/shared/AlertMesssage';
import { alertsSelector } from 'slice-reducers/appSlice';
import { dismissAlert } from 'actions/alerts';
import MobileDownloadPrompt from 'components/shared/MobileDownloadPrompt';
import { logEvent } from 'actions/firebase';
import { MAP_RESIZE_BIGGER, MAP_RESIZE_SMALLER } from 'constants/firebase';
import {
	getDetailPaneSizeFromLS,
	manageLocalStorageVersion,
	saveDetailPaneSizeToLS
} from 'actions/localStorage';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import { Box } from '@mui/material';
const styles = {
	mainContainer: {
		width: '100%',
		height: '100%'
	}
};

const MainContainer = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [pane2Width, setPane2Width] = useState(1); //The actual value of this does not matter. A change in this value is used to trigger a resize in the map component.
	const alerts = useSelector(alertsSelector);
	const { userAgent } = window.navigator;
	const isMobileDevice =
		userAgent.includes('Android') || userAgent.includes('iPhone') || userAgent.includes('iPad');

	const detailPaneSize = getDetailPaneSizeFromLS();

	const onChange = width => {
		if (width > pane2Width) {
			logEvent(MAP_RESIZE_SMALLER);
		} else {
			logEvent(MAP_RESIZE_BIGGER);
		}
		saveDetailPaneSizeToLS(width);
		setPane2Width(width);
	};

	const handleDismissAlert = indexToDismiss => {
		dismissAlert({ alertIndex: indexToDismiss, alerts, dispatch });
	};

	const onNewMajorVersion = () => {
		localStorage.clear();
	};
	const onNewMinorVersion = () => {
		localStorage.clear();
	};

	useEffect(() => {
		manageLocalStorageVersion({ onNewMajorVersion, onNewMinorVersion });
	}, [location]);

	return (
		<Box sx={styles.mainContainer}>
			{isMobileDevice && <MobileDownloadPrompt />}
			{!isMobileDevice && (
				<>
					<SplitPane
						style={styles.mainContainer}
						split='vertical'
						onChange={onChange}
						size={detailPaneSize}
						primary={'second'}
					>
						<ErrorBoundary>
							<MapContainer widthOffset={pane2Width} />
						</ErrorBoundary>
						<ErrorBoundary>
							<RightPanelContainer />
						</ErrorBoundary>
					</SplitPane>
					{alerts.map((a, i) => {
						return (
							<AlertMessage
								autoDismiss={a.autoDismiss}
								key={i}
								index={i}
								variant={a.type}
								message={a.message}
								onClose={handleDismissAlert}
							/>
						);
					})}
				</>
			)}
		</Box>
	);
};

export default MainContainer;
