import { setAlerts } from 'slice-reducers/appSlice';
import { store } from 'services/configureStore';

export const dismissAlert = ({ alertIndex, alerts, dispatch }) => {
	let updatedAlerts = [...alerts];
	updatedAlerts.splice(alertIndex, 1);
	dispatch(setAlerts(updatedAlerts));
};

export const addAlert = alert => dispatch => {
	const updatedAlerts = [...store.getState().app.alerts];
	updatedAlerts.push(alert);
	dispatch(setAlerts(updatedAlerts));
};
