import queryString from 'query-string';

export const getQueryParamsFromLocation = location => {
	const queryParams = location.search.split('?').pop();
	const currentParams = queryString.parse(queryParams, {
		parseBooleans: true,
		arrayFormat: 'bracket',
		sort: false
	});
	return currentParams;
};

export const stringifyQueryObj = queryObj => {
	return queryString.stringify(queryObj, {
		arrayFormat: 'bracket',
		skipNull: false,
		skipEmptyString: false,
		sort: false
	});
};

export const setUrl = ({ queryObject, navigate, path }) => {
	navigate({
		pathname: path,
		search: queryObject ? stringifyQueryObj(queryObject) : ''
	});
};

export const addCoordinatesToUrl = ({ lat, long, navigate, pathname }) => {
	navigate({
		pathname,
		search: `lat=${lat}&long=${long}`
	});
};
