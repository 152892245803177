import { useDispatch } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { createErrorAlert } from 'utils/generalUtils';
import { addAlert } from 'actions/alerts';

const CustomErrorBoundary = ({ children }) => {
	const dispatch = useDispatch();

	const ErrorFallback = () => null;

	const onError = () => {
		const alert = createErrorAlert({
			message: 'An error occurred and has been reported to our team.'
		});
		dispatch(addAlert(alert));
	};

	return (
		<ErrorBoundary fallback={ErrorFallback} onError={onError}>
			{children}
		</ErrorBoundary>
	);
};

export default CustomErrorBoundary;
