import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { createSlice } from '@reduxjs/toolkit';
import { getSelectedMapStyleFromLS } from 'actions/localStorage';
import { mapDrawStyles } from 'constants/mapConstants';
import DirectSelectModeOverride from 'utils/mapboxDrawUtils/DirectSelectModeOverride';
import SimpleSelectModeOverride from 'utils/mapboxDrawUtils/SimpleSelectModeOverride';
import DragCircleMode from 'utils/mapboxDrawUtils/DragCircleMode';

const computeInitialState = () => ({
	mapPinCoordinates: {
		lat: '',
		long: ''
	},
	notifyArea: null,
	refreshNotifyAndFlyLayer: false,
	drawInstance: new MapboxDraw({
		displayControlsDefault: false,
		userProperties: true,
		styles: mapDrawStyles,
		modes: {
			...MapboxDraw.modes,
			drag_circle: DragCircleMode,
			direct_select: DirectSelectModeOverride,
			simple_select: SimpleSelectModeOverride
		}
	}),
	radius: null,
	mapLoading: false,
	style: getSelectedMapStyleFromLS(),
	styleOptions: [],
	layers: [],
	visibleMapLayers: [],
	sources: []
});

const mapSlice = createSlice({
	name: 'map',
	initialState: computeInitialState(),
	reducers: {
		dropMapPin(state, action) {
			state.mapPinCoordinates = action.payload;
		},
		setNotifyArea(state, action) {
			state.notifyArea = action.payload;
		},
		setRefreshNotifyAndFlyLayer(state, action) {
			state.refreshNotifyAndFlyLayer = action.payload;
		},
		setRadius(state, action) {
			state.radius = action.payload;
		},
		setMapLoading(state, action) {
			state.mapLoading = action.payload;
		},
		setMapStyle(state, action) {
			state.style = action.payload;
		},
		setVisibleMapLayers(state, action) {
			state.visibleMapLayers = action.payload;
		},
		setMapStyleOptions(state, action) {
			state.styleOptions = action.payload;
		},
		setMapLayers(state, action) {
			state.layers = action.payload;
		},
		setTileServerSources(state, action) {
			state.tileServerSources = action.payload;
		},
		setMapSources(state, action) {
			state.sources = action.payload;
		},
		resetMapSlice() {
			return computeInitialState();
		}
	}
});

export const {
	dropMapPin,
	setNotifyArea,
	setRefreshNotifyAndFlyLayer,
	setRadius,
	setMapLoading,
	setMapStyle,
	setVisibleMapLayers,
	setMapStyleOptions,
	setMapLayers,
	setTileServerSources,
	resetMapSlice,
	setMapSources
} = mapSlice.actions;
export default mapSlice.reducer;

export const mapPinCoordinatesSelector = state => state.map.mapPinCoordinates;
export const notifyAreaSelector = state => state.map.notifyArea;
export const refreshNotifyAndFlyLayerSelector = state => state.map.refreshNotifyAndFlyLayer;
export const drawInstanceSelector = state => state.map.drawInstance;
export const radiusSelector = state => state.map.radius;
export const mapLoadingSelector = state => state.map.mapLoading;
export const mapStyleSelector = state => state.map.style;
export const mapStyleOptionsSelector = state => state.map.styleOptions;
export const visibleMapLayersSelector = state => state.map.visibleMapLayers;
export const mapLayersSelector = state => state.map.layers;
export const mapSourcesSelector = state => state.map.sources;
