// Taken from https://github.com/iamanvesh/mapbox-gl-draw-circle
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import { convertMilesToFeet } from 'utils/mapUtils';

const DirectSelectModeOverride = MapboxDraw.modes.direct_select;

function createSupplementaryPointsForCircle(geojson) {
	const { properties, geometry } = geojson;

	if (!properties.user_isCircle) return null;

	const supplementaryPoints = [];
	const vertices = geometry.coordinates[0].slice(0, -1);
	for (let index = 0; index < vertices.length; index += Math.round(vertices.length / 4)) {
		supplementaryPoints.push(createVertex(properties.id, vertices[index], `0.${index}`, false));
	}
	return supplementaryPoints;
}

DirectSelectModeOverride.dragFeature = function (state, e, delta) {
	moveFeatures(this.getSelected(), delta);
	this.getSelected()
		.filter(feature => feature.properties.isCircle)
		.map(circle => circle.properties.center)
		.forEach(center => {
			center[0] += delta.lng;
			center[1] += delta.lat;
		});
	state.dragMoveLocation = e.lngLat;
};

DirectSelectModeOverride.dragVertex = function (state, e, delta) {
	if (state?.feature?.properties?.isCircle) {
		const center = state.feature.properties.center;
		const movedVertex = [e.lngLat.lng, e.lngLat.lat];
		const radiusInMiles = distance(point(center), point(movedVertex), { units: 'miles' });
		const circleFeature = circle(center, radiusInMiles, { units: 'miles' });
		state.feature.incomingCoords(circleFeature.geometry.coordinates);
		const radiusInFeet = convertMilesToFeet(radiusInMiles);
		state.feature.properties.radiusInFeet = radiusInFeet;
	}
	// else if (state.feature.properties.isRectangle) {
	//Need to disable selection of top left vertex and allow drag of rectangle
	//
	// 	const topLeftPointCoordinates = state.feature.coordinates[0][0];
	//
	// 	// Upper right vertex - maxX, minY
	// 	state.feature.updateCoordinate('0.1', e.lngLat.lng, topLeftPointCoordinates[1]);
	//
	// 	// Lower right vertex - maxX, maxY
	// 	state.feature.updateCoordinate('0.2', e.lngLat.lng, e.lngLat.lat);
	//
	// 	// Lower left vertex - minX, maxY
	// 	state.feature.updateCoordinate('0.3', topLeftPointCoordinates[0], e.lngLat.lat);
	//
	// 	// Starting point again
	// 	state.feature.updateCoordinate('0.4', topLeftPointCoordinates[0], topLeftPointCoordinates[1]);
	// }
	else {
		const selectedCoords = state.selectedCoordPaths.map(coord_path =>
			state.feature.getCoordinate(coord_path)
		);
		const selectedCoordPoints = selectedCoords.map(coords => ({
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'Point',
				coordinates: coords
			}
		}));

		const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
		for (let i = 0; i < selectedCoords.length; i++) {
			const coord = selectedCoords[i];
			state.feature.updateCoordinate(
				state.selectedCoordPaths[i],
				coord[0] + constrainedDelta.lng,
				coord[1] + constrainedDelta.lat
			);
		}
	}
};

DirectSelectModeOverride.toDisplayFeatures = function (state, geojson, push) {
	if (state.featureId === geojson.properties.id) {
		geojson.properties.active = Constants.activeStates.ACTIVE;
		push(geojson);
		const supplementaryPoints = geojson.properties.user_isCircle
			? createSupplementaryPointsForCircle(geojson)
			: createSupplementaryPoints(geojson, {
					map: this.map,
					midpoints: true,
					selectedPaths: state.selectedCoordPaths
				});
		supplementaryPoints.forEach(push);
	} else {
		geojson.properties.active = Constants.activeStates.INACTIVE;
		push(geojson);
	}
	this.fireActionable(state);
};

export default DirectSelectModeOverride;
