import {
	setAdvisoriesLoading,
	setAdvisoriesObject
} from 'slice-reducers/locationAndAdvisoriesSlice';
import {
	fetchMapLayerSourceData,
	getAdvisories,
	getTileServerRegionSpecificLayersAndSources
} from 'apis/map.api';
import { createPointFeature, getSelectedMapLayerList, updateLayerSettings } from 'utils/mapUtils';
import { setUserLocation, setUserLocationLoading } from 'slice-reducers/appSlice';
import { geolocateUser } from 'actions/geolocation';
import { logEvent } from 'actions/firebase';
import { PIN_DROP_GPS } from 'constants/firebase';
import { notifyAndFlySourceId } from 'constants/mapConstants';
import { addCoordinatesToUrl } from 'actions/url';
import {
	mapSourcesSelector,
	setMapLayers,
	setMapLoading,
	setMapSources,
	setMapStyleOptions,
	setVisibleMapLayers
} from 'slice-reducers/mapSlice';
import { getSelectedMapLayersFromLS, saveSelectedMapLayersToLS } from 'actions/localStorage';

export const fetchAndSetAdvisories =
	({ lat, long, showLoader = true }) =>
	async dispatch => {
		if (showLoader) {
			dispatch(setAdvisoriesLoading(true));
		}

		const res = await dispatch(getAdvisories({ lat, long }));
		dispatch(setAdvisoriesObject(res));
		if (showLoader) {
			dispatch(setAdvisoriesLoading(false));
		}
	};

export const fetchAndSetUserLocation =
	({ navigate, lat, long, location }) =>
	async dispatch => {
		dispatch(setUserLocationLoading(true));
		const userLocation = await dispatch(geolocateUser());
		if (userLocation) {
			const location = createPointFeature({
				lat: userLocation.lat,
				long: userLocation.long,
				place_name: `LAT: ${Number(userLocation.lat).toFixed(6)} || LONG: ${Number(
					userLocation.long
				).toFixed(6)}`,
				optionType: 'userLocation'
			});
			dispatch(setUserLocation(location));
			dispatch(setUserLocationLoading(false));
			if (!lat || !long) {
				//Set marker and url params to user location if another location is not already chosen
				logEvent(PIN_DROP_GPS);
				addCoordinatesToUrl({
					lat: userLocation.lat,
					long: userLocation.long,
					navigate,
					pathname: location.pathname
				});
			}
		}
	};

export const fetchNotifyAndFlyLayer =
	({ map }) =>
	async (dispatch, getState) => {
		const sources = mapSourcesSelector(getState());
		const notifyAndFlySource = sources.find(source => source.id === notifyAndFlySourceId);
		const notifyAndFlyLayerUrl = notifyAndFlySource?.data;
		if (notifyAndFlyLayerUrl) {
			const updatedNotifyAndFlyData = await dispatch(fetchMapLayerSourceData(notifyAndFlyLayerUrl));
			const currentLayer = map.getSource(notifyAndFlySourceId);
			if (currentLayer) {
				currentLayer.setData(updatedNotifyAndFlyData);
			}
		}
	};

export const fetchNotifyLayerAndAdvisories =
	({ map, lat, long, showLoader }) =>
	async dispatch => {
		await dispatch(fetchNotifyAndFlyLayer({ map }));
		//Do not show loading state, just update UI quietly
		if (lat && long) {
			await dispatch(fetchAndSetAdvisories({ lat, long, showLoader }));
		}
	};

export const fetchMapLayers =
	({ region }) =>
	async dispatch => {
		dispatch(setMapLoading(true));
		const localStorageSelectedMapLayers = getSelectedMapLayersFromLS();
		const res = await dispatch(getTileServerRegionSpecificLayersAndSources(region));
		const regionTileServerSources = res?.sources || [];
		const regionTileServerLayers = res?.layers || [];
		const mapStyleOptions = res?.map_styles || [];

		dispatch(setMapSources(regionTileServerSources));
		dispatch(setMapLayers(regionTileServerLayers));
		dispatch(setMapStyleOptions(mapStyleOptions));

		//Set initial map layer settings in local storage
		const updatedMapLayerSettings = updateLayerSettings({
			layerToggleList: regionTileServerLayers,
			currentSettings: localStorageSelectedMapLayers
		});
		saveSelectedMapLayersToLS(updatedMapLayerSettings);

		//Set initial visible map layers in redux
		const visibleMapLayers = getSelectedMapLayerList({
			allLayers: regionTileServerLayers,
			currentSettings: updatedMapLayerSettings
		});
		dispatch(setVisibleMapLayers(visibleMapLayers));
		dispatch(setMapLoading(false));
	};
