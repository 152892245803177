import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, SnackbarContent, Box } from '@mui/material';
import { $danger, $primary, $success, $warning } from 'styles/colors';

const AlertMessage = ({ message, variant, onClose, index, autoDismiss }) => {
	const snackbarHeight = 56;
	const topSpacer = 10 * index;
	const marginTop = index * snackbarHeight + topSpacer;

	const styles = {
		success: {
			backgroundColor: $success
		},
		error: {
			backgroundColor: $danger
		},
		info: {
			backgroundColor: $primary
		},
		warning: {
			backgroundColor: $warning
		},
		icon: {
			fontSize: '20px'
		},
		iconVariant: {
			opacity: 0.9,
			marginRight: '10px'
		},
		message: {
			display: 'flex',
			alignItems: 'center'
		},
		placement: {
			position: 'absolute',
			top: '20px',
			right: '20px'
		},
		snackbarMain: {
			marginTop: `${marginTop}px`
		}
	};

	const [isOpen, setIsOpen] = useState(true);

	const variantIcon = {
		success: CheckCircleIcon,
		warning: WarningIcon,
		error: ErrorIcon,
		info: InfoIcon
	};
	const Icon = variantIcon[variant];

	const handleClose = (e, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setIsOpen(false);
		onClose(index);
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			open={isOpen}
			autoHideDuration={autoDismiss ? 5000 : null}
			sx={styles.snackbarMain}
			onClose={handleClose}
		>
			<SnackbarContent
				sx={styles[variant]}
				aria-describedby='client-snackbar'
				message={
					<Box component='span' id='client-snackbar' sx={styles.message}>
						<Icon sx={{ ...styles.icon, ...styles.iconVariant }} />
						{message}
					</Box>
				}
				action={[
					<IconButton
						key='close'
						aria-label='close'
						color='inherit'
						onClick={handleClose}
						size='large'
					>
						<CloseIcon sx={styles.icon} />
					</IconButton>
				]}
			/>
		</Snackbar>
	);
};

AlertMessage.propTypes = {};

export default AlertMessage;
