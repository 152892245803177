//These functions require passing in a map instance

import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { $primary } from 'styles/colors';
import { FullscreenControl, GeolocateControl, NavigationControl } from 'mapbox-gl';
import { featureCollection } from '@turf/helpers';
import { convertMilesToFeet } from 'utils/mapUtils';

export const convertAreaIntoBoundingBoxSWNE = area => {
	const boundingBox = bbox(area);
	const southWest = [boundingBox[0], boundingBox[1]];
	const northEast = [boundingBox[2], boundingBox[3]];
	return { southWest, northEast };
};

export const addMapControls = map => {
	map.addControl(
		new FullscreenControl({
			container: document.querySelector('body')
		}),
		'bottom-right'
	);
	map.addControl(new NavigationControl(), 'bottom-right');
	map.addControl(
		new GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}),
		'bottom-right'
	);
};

export const checkInsideMapBounds = ({ lat, long, map }) => {
	const mapBounds = map.getBounds();
	const { _ne, _sw } = mapBounds;
	const latInView = lat < _ne.lat && lat > _sw.lat;
	const longInView = long < _ne.lng && long > _sw.lng;
	if (!latInView || !longInView) {
		return false;
	} else {
		return true;
	}
};

export const addNotifyFeatureLayerToMap = ({ map, feature }) => {
	const fillId = 'notify-area-fill';
	const lineId = 'notify-area-line';

	if (map.getLayer(fillId)) {
		map.removeLayer(fillId);
	}
	if (map.getSource(fillId)) {
		map.removeSource(fillId);
	}
	if (map.getLayer(lineId)) {
		map.removeLayer(lineId);
	}
	if (map.getSource(lineId)) {
		map.removeSource(lineId);
	}

	map.addLayer({
		id: fillId,
		type: 'fill',
		source: {
			type: 'geojson',
			data: feature
		},
		paint: {
			'fill-color': $primary,
			'fill-opacity': 0.5
		},
		layout: {}
	});

	map.addLayer({
		id: lineId,
		type: 'fill',
		source: {
			type: 'geojson',
			data: feature
		},
		paint: {
			'fill-color': '#fff',
			'fill-opacity': 0.5
		},
		layout: {
			'line-join': 'round',
			'line-cap': 'round'
		}
	});
};

export const createNotifyPolygonFeatureCollection = ({ lat, long }) => {
	const radius = 0.7575758; // 4000 ft
	const options = {
		units: 'miles',
		properties: { isCircle: true, center: [long, lat], radiusInFeet: convertMilesToFeet(radius) }
	};
	return featureCollection([circle([long, lat], radius, options)]);
};
