import { setGeolocationError } from 'slice-reducers/appSlice';

export const geolocateUser = () => dispatch => {
	return new Promise((resolve, reject) => {
		const onSuccess = position => {
			const lat = position.coords.latitude;
			const long = position.coords.longitude;
			dispatch(setGeolocationError(null));
			resolve({ lat, long });
		};

		const onError = error => {
			reject(error);
		};

		const options = {
			enableHighAccuracy: false,
			timeout: 10000,
			maximumAge: 600000 //10 min cache
		};
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
		} else {
			reject({
				message: 'Unable to geolocate.'
			});
		}
	}).catch(error => {
		dispatch(setGeolocationError(error));
	});
};
