import './App.scss';
import { Provider } from 'react-redux';
import { store } from 'services/configureStore';
import AppThemeProvider from 'AppThemeProvider';
import { BrowserRouter } from 'react-router-dom';
import MainContainer from 'components/MainContainer';
import * as Sentry from '@sentry/react';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_KEY } from 'constants/envVars';

LicenseInfo.setLicenseKey(MUI_KEY);

const App = () => {
	return (
		<Provider store={store}>
			<AppThemeProvider>
				<BrowserRouter>
					<MainContainer />
				</BrowserRouter>
			</AppThemeProvider>
		</Provider>
	);
};

export default Sentry.withProfiler(App);
