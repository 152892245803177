import { defaultMapStyleId } from 'constants/mapConstants';
import { defaultDetailPaneSize } from 'constants/appConstants';
import {
	DETAIL_PANE_SIZE,
	LS_LAST_UPDATED_VERSION,
	RECENT_SEARCHES,
	SELECTED_MAP_LAYERS,
	SELECTED_MAP_STYLE
} from 'constants/localStorageKeys';
import { REACT_APP_VERSION } from 'constants/envVars';

export const saveSelectedMapLayersToLS = layers => {
	localStorage.setItem(SELECTED_MAP_LAYERS, JSON.stringify(layers));
};

export const getSelectedMapLayersFromLS = () => {
	const layerSettings = localStorage.getItem(SELECTED_MAP_LAYERS);
	if (layerSettings) {
		return JSON.parse(layerSettings);
	} else {
		return {};
	}
};

export const saveSelectedMapStyleToLS = styleId => {
	localStorage.setItem(SELECTED_MAP_STYLE, styleId);
};

export const getSelectedMapStyleFromLS = () => {
	const selectedMapStyle = localStorage.getItem(SELECTED_MAP_STYLE);
	if (selectedMapStyle) {
		return selectedMapStyle;
	} else {
		return defaultMapStyleId;
	}
};

export const addToRecentSearchesLS = location => {
	const option = { ...location, optionType: RECENT_SEARCHES };
	const max = 5;
	const currentRecentSearches = getRecentSearchesFromLS();
	const updatedRecentSearches = [...currentRecentSearches];
	const index = currentRecentSearches.findIndex(item => item.id === option.id);
	if (index === -1) {
		updatedRecentSearches.unshift(option);
		if (updatedRecentSearches.length > max) {
			updatedRecentSearches.pop();
		}
	}
	localStorage.setItem(RECENT_SEARCHES, JSON.stringify(updatedRecentSearches));
};

export const getRecentSearchesFromLS = () => {
	const recentSearches = localStorage.getItem(RECENT_SEARCHES);
	if (recentSearches) {
		return JSON.parse(recentSearches);
	} else {
		return [];
	}
};

export const saveDetailPaneSizeToLS = size => {
	localStorage.setItem(DETAIL_PANE_SIZE, size);
};

export const getDetailPaneSizeFromLS = () => {
	const savedSize = localStorage.getItem(DETAIL_PANE_SIZE);
	if (savedSize && Number(savedSize)) {
		return Number(savedSize);
	} else {
		return defaultDetailPaneSize;
	}
};

export const getMinorVersion = version => {
	const splitVersion = version.split('.');
	const minorVersion = splitVersion[1];
	return minorVersion;
};

export const getMajorVersion = version => {
	const splitVersion = version.split('.');
	const majorVersion = splitVersion[0];
	return majorVersion;
};

export const getPatchVersion = version => {
	const splitVersion = version.split('.');
	const minorVersion = splitVersion[2];

	return minorVersion;
};

export const manageLocalStorageVersion = ({
	onNewMajorVersion,
	onNewMinorVersion,
	onNewPatchVersion
}) => {
	const deployedVersion = REACT_APP_VERSION;
	if (deployedVersion) {
		const deployedMajorVersion = getMajorVersion(deployedVersion);
		const deployedMinorVersion = getMinorVersion(deployedVersion);
		const deployedPatchVersion = getPatchVersion(deployedVersion);
		const lsLastUpdatedVersion = localStorage.getItem(LS_LAST_UPDATED_VERSION);

		if (lsLastUpdatedVersion) {
			const lsMajorVersion = getMajorVersion(lsLastUpdatedVersion);
			const lsMinorVersion = getMinorVersion(lsLastUpdatedVersion);
			const lsPatchVersion = getPatchVersion(lsLastUpdatedVersion);
			const newMajorVersion = lsMajorVersion !== deployedMajorVersion;
			const newMinorVersion = lsMinorVersion !== deployedMinorVersion;
			const newPatchVersion = lsPatchVersion !== deployedPatchVersion;

			//Update version in local storage
			if (newMajorVersion || newMinorVersion || newPatchVersion) {
				localStorage.removeItem(LS_LAST_UPDATED_VERSION);
				localStorage.setItem(LS_LAST_UPDATED_VERSION, deployedVersion);
			}

			//Callback for each type of version change
			if (newMajorVersion && onNewMajorVersion) {
				onNewMajorVersion();
			} else if (newMinorVersion && onNewMinorVersion) {
				onNewMinorVersion();
			} else if (newPatchVersion && onNewPatchVersion) {
				onNewPatchVersion();
			}
		} else {
			//Set lsLastUpdatedVersion for the first time
			localStorage.setItem(LS_LAST_UPDATED_VERSION, deployedVersion);
		}
	}
};
