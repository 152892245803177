import { configureStore } from '@reduxjs/toolkit';
import mapSlice from 'slice-reducers/mapSlice';
import appSlice from 'slice-reducers/appSlice';
import locationAndAdvisoriesSlice from 'slice-reducers/locationAndAdvisoriesSlice';
import { sentryReduxEnhancer } from 'services/sentry';

export const store = configureStore({
	reducer: {
		map: mapSlice,
		app: appSlice,
		locationAndAdvisories: locationAndAdvisoriesSlice
	},
	middleware: getDefaultMiddleware => [
		...getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false
		})
	],
	enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer)
});
