export const objectHasKeys = obj => {
	if (!obj) {
		return false;
	}
	return Object.keys(obj).length > 0;
};

export const createErrorAlert = ({ error, message }) => {
	let text = `${
		error?.status ? `${error.status}: ` : ' '
	}An error occurred and has been reported to our team.`;
	if (message) {
		text = message;
	}

	return {
		message: text,
		type: 'error',
		autoDismiss: true
	};
};
