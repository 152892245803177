import { Paper } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import { lazy, Suspense } from 'react';
import * as Sentry from '@sentry/react';
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const NotifyContainer = lazy(() => import('components/rightPanel/notify/NotifyContainer'));
const SearchAndAdvisoriesContainer = lazy(
	() => import('components/rightPanel/searchAndAdvisories/SearchAndAdvisoriesContainer')
);

const styles = {
	main: {
		width: '100%',
		height: '100%'
	}
};

const RightPanelContainer = () => {
	return (
		<Paper sx={styles.main}>
			<SentryRoutes>
				<Route
					path='/'
					element={
						<ErrorBoundary>
							<Suspense fallback={<div />}>
								<SearchAndAdvisoriesContainer />
							</Suspense>
						</ErrorBoundary>
					}
				/>
				<Route
					path='/notify'
					element={
						<ErrorBoundary>
							<Suspense fallback={<div />}>
								<NotifyContainer />
							</Suspense>
						</ErrorBoundary>
					}
				/>
			</SentryRoutes>
		</Paper>
	);
};

export default RightPanelContainer;
