import 'utils/detectInternetExplorer';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { initSentry } from 'services/sentry';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'constants/firebase';
import { initializeFirebaseLogger } from 'actions/firebase';

initSentry();

const firebaseApp = initializeApp(firebaseConfig);
initializeFirebaseLogger(firebaseApp);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
