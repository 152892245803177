import Pusher from 'pusher-js';
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from 'constants/envVars';
import { setPusherSocketId } from 'slice-reducers/appSlice';
import { store } from 'services/configureStore';
import { addAlert } from 'actions/alerts';
import { createErrorAlert } from 'utils/generalUtils';

export const pusher = new Pusher(PUSHER_APP_KEY, { cluster: PUSHER_APP_CLUSTER });

pusher.connection.bind('state_change', state => {
	switch (state.current) {
		case 'connecting':
			return;
		case 'connected':
			const socketId = pusher.connection.socket_id;
			store.dispatch(setPusherSocketId(socketId));
			return;
		case 'unavailable':
			const unavailable = createErrorAlert({
				message: 'Real-time updates are currently unavailable.'
			});
			store.dispatch(addAlert(unavailable));
			return;
		case 'failed':
			const failedToConnect = createErrorAlert({
				message: 'Failed to connect to real-time updates.'
			});
			store.dispatch(addAlert(failedToConnect));
			return;
		case 'disconnected':
			return;
		default:
			return;
	}
});
