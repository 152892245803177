import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	CardContent,
	Fab,
	List,
	ListItem,
	ListItemSecondaryAction,
	Popover,
	Radio,
	Switch,
	Typography
} from '@mui/material';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import {
	changeMapLayerToggledValue,
	getMapLayerControlsSections,
	getSelectedMapLayerList,
	toggleMapLayer
} from 'utils/mapUtils';
import {
	mapStyleOptionsSelector,
	mapStyleSelector,
	setMapStyle,
	setVisibleMapLayers,
	mapLayersSelector
} from 'slice-reducers/mapSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	getSelectedMapLayersFromLS,
	saveSelectedMapLayersToLS,
	saveSelectedMapStyleToLS
} from 'actions/localStorage';
import { logEvent } from 'actions/firebase';
import { MAP_SETTINGS_OPEN, MAP_SETTINGS_CLOSE } from 'constants/firebase';

const MapLayerControls = ({
	disabledToggles = [],
	layerPickerPosition = {
		top: '50px',
		right: '50px'
	},
	disabled,
	onChangeCallback
}) => {
	const styles = {
		mapStyleLayersPosition: {
			position: 'absolute',
			top: '50px',
			right: '50px',
			zIndex: 1200,
			...layerPickerPosition
		},
		accordionSummary: {
			flexDirection: 'row-reverse',
			'& .MuiAccordionSummary-content': {
				marginLeft: '20px',
				display: 'flex',
				justifyContent: 'space-between'
			},
			'& .MuiAccordionSummary-content.Mui-expanded': {
				marginLeft: '20px'
			}
		},
		avatarContainer: {
			width: '80px',
			height: '80px',
			borderRadius: '25px'
		},
		subtitle: {
			fontWeight: '500',
			fontSize: '.9rem',
			color: '#495057',
			paddingRight: '30px',
			lineHeight: '1.3rem'
		},
		cardContent: {
			padding: '0px 16px !important'
		},
		paperPropsStyle: {
			maxHeight: '95vh',
			width: '600px',
			overflowY: 'scroll'
		}
	};

	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState();
	const open = Boolean(anchorEl);
	const mapLayers = useSelector(mapLayersSelector);
	const mapStylesOptions = useSelector(mapStyleOptionsSelector);
	const selectedMapStyle = useSelector(mapStyleSelector);
	const localStorageSelectedMapLayers = getSelectedMapLayersFromLS();

	const sections = getMapLayerControlsSections({
		layers: mapLayers
	});

	const onOpen = e => {
		logEvent(MAP_SETTINGS_OPEN);
		setAnchorEl(e.currentTarget);
	};

	const onClose = () => {
		logEvent(MAP_SETTINGS_CLOSE);
		setAnchorEl(null);
	};

	const onLayerToggle = layer => {
		const updatedMapLayerSettings = toggleMapLayer({
			currentSettings: localStorageSelectedMapLayers,
			layerId: layer.id
		});
		saveSelectedMapLayersToLS(updatedMapLayerSettings);
		const visibleMapLayers = getSelectedMapLayerList({
			allLayers: mapLayers,
			currentSettings: updatedMapLayerSettings
		});
		dispatch(setVisibleMapLayers(visibleMapLayers));
	};

	const onToggleSingle = i => {
		onChangeCallback();
		onLayerToggle(i);
	};

	const handleSectionToggle = (e, s) => {
		onChangeCallback();
		e.stopPropagation();
		let updatedMapLayerSettings = localStorageSelectedMapLayers;
		const newValue = !sectionIsChecked(s);
		s.items.forEach(i => {
			const toggleDisabled = disabledToggles && disabledToggles.includes(i.id);
			if (!toggleDisabled) {
				updatedMapLayerSettings = changeMapLayerToggledValue({
					currentSettings: updatedMapLayerSettings,
					layerId: i.id,
					value: newValue
				});
			}
		});

		saveSelectedMapLayersToLS(updatedMapLayerSettings);
		const visibleMapLayers = getSelectedMapLayerList({
			allLayers: mapLayers,
			currentSettings: updatedMapLayerSettings
		});
		dispatch(setVisibleMapLayers(visibleMapLayers));
	};

	const handleSectionToggleClick = e => {
		e.stopPropagation();
	};

	const sectionIsChecked = s => {
		return s.items.every(i => localStorageSelectedMapLayers[i.id]);
	};

	const onChangeStyle = useCallback(
		newStyle => {
			logEvent(`style_${newStyle}`);
			dispatch(setMapStyle(newStyle));
			saveSelectedMapStyleToLS(newStyle);
		},
		[dispatch]
	);

	useEffect(() => {
		if (mapStylesOptions.length > 0) {
			const selectedStyleFound = mapStylesOptions.find(s => s.id === selectedMapStyle);
			if (!selectedStyleFound) {
				//Default to the first map style if the user's saved style doesn't match one of the style options returned from the API
				onChangeStyle(mapStylesOptions[0].id);
			}
		}
	}, [mapStylesOptions, selectedMapStyle, onChangeStyle]);

	return (
		<>
			<Fab color='primary' size='medium' onClick={onOpen} sx={styles.mapStyleLayersPosition}>
				<LayersOutlinedIcon />
			</Fab>
			<Popover
				id='long-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				PaperProps={{
					style: styles.paperPropsStyle
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				{sections.map((s, key) => {
					return (
						<Accordion key={s.id} disableGutters>
							<AccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMoreIcon />}>
								<Typography variant='h6'>{s.title}</Typography>
								{s.id !== 'base_map' && (
									<Switch
										onClick={handleSectionToggleClick}
										onChange={e => handleSectionToggle(e, s)}
										checked={sectionIsChecked(s)}
										color='primary'
										disabled={disabled}
									/>
								)}
							</AccordionSummary>
							<AccordionDetails>
								{s.id === 'base_map' && (
									<List>
										{mapStylesOptions?.map((style, i) => {
											return (
												<ListItem key={i}>
													<Avatar
														sx={styles.avatarContainer}
														alt={`Avatar ${style.name}`}
														src={style.img_url}
													/>
													<CardContent sx={styles.cardContent}>
														<Typography variant='h6'>{style.name}</Typography>
														<Typography variant='subtitle1' sx={styles.subtitle}>
															{style.description}
														</Typography>
														<ListItemSecondaryAction>
															<Box>
																<Radio
																	disabled={disabled}
																	checked={selectedMapStyle === style.id}
																	onChange={() => onChangeStyle(style.id)}
																	value={style.id}
																	name={style.name}
																	aria-label={style.id}
																/>
															</Box>
														</ListItemSecondaryAction>
													</CardContent>
												</ListItem>
											);
										})}
									</List>
								)}
								<List>
									{s.items.map(i => {
										const toggleDisabled = disabledToggles && disabledToggles.includes(i.id);
										const checked = localStorageSelectedMapLayers[i.id];
										return (
											<ListItem key={i.id}>
												<Avatar
													sx={styles.avatarContainer}
													alt={`Avatar ${i.id}`}
													src={i.thumbnail_url}
												/>
												<CardContent sx={styles.cardContent}>
													<Typography variant='h6'>{i.title}</Typography>
													<Typography variant='subtitle1' sx={styles.subtitle}>
														{i.description}
													</Typography>
													<ListItemSecondaryAction>
														<Box>
															<Switch
																disabled={toggleDisabled || disabled}
																onChange={() => onToggleSingle(i)}
																checked={checked}
																color='primary'
															/>
														</Box>
													</ListItemSecondaryAction>
												</CardContent>
											</ListItem>
										);
									})}
								</List>
							</AccordionDetails>
						</Accordion>
					);
				})}
			</Popover>
		</>
	);
};

MapLayerControls.propTypes = {
	disabledToggles: PropTypes.arrayOf(PropTypes.string),
	layerPickerPosition: PropTypes.object,
	disabled: PropTypes.bool
};
export default MapLayerControls;
