import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userLocationLoading: false
};

const locationAndAdvisoriesSlice = createSlice({
	name: 'locationAndAdvisories',
	initialState,
	reducers: {
		setAdvisoriesLoading(state, action) {
			state.advisoriesLoading = action.payload;
		},
		setAdvisoriesObject(state, action) {
			state.advisoriesObject = action.payload;
		},
		setInvalidCoordinates(state, action) {
			state.invalideCoordinates = action.payload;
		},
		setPlaceDetails(state, action) {
			state.placeDetails = action.payload;
		}
	}
});

export const { setAdvisoriesLoading, setInvalidCoordinates, setAdvisoriesObject, setPlaceDetails } =
	locationAndAdvisoriesSlice.actions;
export default locationAndAdvisoriesSlice.reducer;

export const advisoriesLoadingSelector = state => state.locationAndAdvisories.advisoriesLoading;
export const advisoriesObjectSelector = state => state.locationAndAdvisories.advisoriesObject;
export const placeDetailsSelector = state => state.locationAndAdvisories.placeDetails;
