import { REACT_APP_ENV, REACT_APP_VERSION, SENTRY_DSN } from 'constants/envVars';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes
} from 'react-router-dom';

export const initSentry = () => {
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: REACT_APP_ENV,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			}),
			Sentry.replayIntegration({
				maskAllText: true,
				blockAllMedia: true
			})
		],
		maxBreadcrumbs: 50,
		tracesSampleRate: 1.0,
		release: REACT_APP_VERSION,
		normalizeDepth: 10,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0.05
	});
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
