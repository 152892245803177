import {
	FREE_COURSES,
	GET_LAANC,
	MENU_CONTACT,
	NOTIFY_COMMERCIAL,
	NOTIFY_FIRST_RESPONDER,
	NOTIFY_GOVERNMENT,
	NOTIFY_RECREATIONAL,
	OPEN,
	SHARE_EMAIL,
	SHARE_FACEBOOK,
	SHARE_LINK,
	SHARE_LINKEDIN,
	SHARE_TWITTER,
	STYLE_AC_DEFAULT,
	STYLE_AC_TERRAIN,
	STYLE_SATELLITE,
	MENU_TERMS,
	MENU_PILOT_INSTITUTE,
	MENU_ALOFT,
	MENU_B4UFLY,
	MENU_SAFETY_GUIDELINES
} from 'constants/firebase';
import {
	notifyCommercial,
	notifyFirstResponder,
	notifyGovernment,
	notifyRecreational
} from 'constants/notifyAndFlyConstants';
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';

let analytics;
export const initializeFirebaseLogger = firebaseApp => {
	analytics = getAnalytics(firebaseApp);
	firebaseLogEvent(analytics, OPEN);
};

export const logEvent = name => {
	firebaseLogEvent(analytics, name);
};

export const handleMapStyleChangeEvent = styleId => {
	switch (styleId) {
		case 'ac-default':
			logEvent(STYLE_AC_DEFAULT);
			return;
		case 'ac-terrain':
			logEvent(STYLE_AC_TERRAIN);
			return;
		case 'satellite':
			logEvent(STYLE_SATELLITE);
			return;
		default:
			return;
	}
};

export const handleMenuItemClickEvent = name => {
	switch (name) {
		case 'Get LAANC':
			logEvent(GET_LAANC);
			return;
		case 'Free Courses':
			logEvent(FREE_COURSES);
			return;
		case 'Contact Us':
			logEvent(MENU_CONTACT);
			return;
		case 'About Pilot Institute':
			logEvent(MENU_PILOT_INSTITUTE);
			return;
		case 'About Aloft':
			logEvent(MENU_ALOFT);
			return;
		case 'About B4UFLY':
			logEvent(MENU_B4UFLY);
			return;
		case 'Safety Guidelines':
			logEvent(MENU_SAFETY_GUIDELINES);
			return;
		case 'Terms of Use':
			logEvent(MENU_TERMS);
			return;
		default:
			return;
	}
};

export const handleShareButtonClickEvent = platform => {
	switch (platform) {
		case 'twitter':
			logEvent(SHARE_TWITTER);
			return;
		case 'facebook':
			logEvent(SHARE_FACEBOOK);
			return;
		case 'linkedin':
			logEvent(SHARE_LINKEDIN);
			return;
		case 'email':
			logEvent(SHARE_EMAIL);
			return;
		case 'link':
			logEvent(SHARE_LINK);
			return;
		default:
			return;
	}
};

export const handleSubmitNotifyEvent = type => {
	switch (type) {
		case notifyRecreational:
			logEvent(NOTIFY_RECREATIONAL);
			return;
		case notifyCommercial:
			logEvent(NOTIFY_COMMERCIAL);
			return;
		case notifyGovernment:
			logEvent(NOTIFY_GOVERNMENT);
			return;
		case notifyFirstResponder:
			logEvent(NOTIFY_FIRST_RESPONDER);
			return;
		default:
			return;
	}
};
