import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	alerts: [],
	pusherSocketId: null
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setGeolocationError(state, action) {
			state.geolocationError = action.payload;
			state.userLocationLoading = false;
		},
		setUserLocation(state, action) {
			state.userCoordinates = action.payload;
		},
		setUserLocationLoading(state, action) {
			state.userLocationLoading = action.payload;
		},
		setAlerts(state, action) {
			state.alerts = action.payload;
		},
		setPusherSocketId(state, action) {
			state.pusherSocketId = action.payload;
		}
	}
});

export const {
	setGeolocationError,
	setUserLocation,
	setUserLocationLoading,
	setAlerts,
	setPusherSocketId
} = appSlice.actions;
export default appSlice.reducer;

export const geolocationErrorSelector = state => state.app.geolocationError;
export const userCoordinatesSelector = state => state.app.userCoordinates;
export const userLocationLoadingSelector = state => state.app.userLocationLoading;
export const alertsSelector = state => state.app.alerts;
