import { point } from '@turf/helpers';

export const validateCoordinates = ({ lat, long }) => {
	const latitude = lat >= -90 && lat <= 90 ? true : false; // lat -90, 90
	const longtitude = long >= -180 && long <= 180 ? true : false; // long -180, 180
	return [latitude, longtitude].every(el => el);
};

export const createSourcesStringFromSettingsObject = settings => {
	const sources = [];
	for (const [key, value] of Object.entries(settings)) {
		if (value) {
			sources.push(key);
		}
	}
	return sources.join(',');
};

export const toggleMapLayer = ({ currentSettings, layerId }) => {
	const updatedSettings = { ...currentSettings };
	updatedSettings[layerId] = !updatedSettings[layerId];
	return updatedSettings;
};

export const updateSettingsWithNewMapSource = ({ sources, currentSettings }) => {
	const updatedSettings = { ...currentSettings };
	sources.forEach(source => {
		const hasSetting = Object.hasOwnProperty.call(currentSettings, source.id);
		if (!hasSetting) {
			updatedSettings[source.id] = true;
		}
	});
	return updatedSettings;
};

export const createPointFeature = ({ coordinates, id, properties, lat, long, ...rest }) => {
	if (coordinates && (lat || long)) {
		throw new Error(
			'Invalid parameters - Provide either a coordinates parameter OR lat and long parameters.'
		);
	}

	if (!coordinates && (lat === undefined || long === undefined)) {
		throw new Error('The coordinates parameter OR lat and long parameters are required.');
	}
	const coords = coordinates ? coordinates : [long, lat];
	const geoJSONPoint = point(coords, properties, { id });
	return {
		...geoJSONPoint,
		...rest
	};
};

export const coordinatesRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/gim;

export const looksLikeCoordinates = value => {
	const matchedValue = value.match(coordinatesRegex);
	return matchedValue ? matchedValue.length > 0 : false;
};

export const convertCoordinatesToMapboxLocation = ({ lat, long, optionType }) => {
	return createPointFeature({
		lat,
		long,
		id: `${long}, ${lat}`,
		center: [long, lat],
		address: '-',
		place_name: `LAT: ${Number(lat).toFixed(6)} || LONG: ${Number(long).toFixed(6)}`,
		optionType
	});
};

export const isValidCoordinates = (lat, long) => {
	const latitude = lat >= -90 && lat <= 90 ? true : false; // lat -90, 90
	const longitude = long >= -180 && long <= 180 ? true : false; // long -180, 180
	return [latitude, longitude].every(el => el);
};

export const feetInMile = 5280;

export const convertMilesToFeet = miles => {
	return miles * feetInMile;
};

export const convertFeetToMiles = feet => {
	return feet / feetInMile;
};

export const changeMapLayerToggledValue = ({ currentSettings, layerId, value }) => {
	const updatedSettings = { ...currentSettings };
	updatedSettings[layerId] = value;
	return updatedSettings;
};
export const getMapLayerControlsSections = ({ layers, sources }) => {
	const layersList =
		layers?.map(l => ({
			...l,
			isLayer: true
		})) || [];

	const sourcesList =
		sources?.map(s => ({
			...s,
			isSource: true,
			title: s.name,
			thumbnail_url: s.img_url
		})) || [];

	const allItems = [...layersList, ...sourcesList];

	const sectionsLayout = [
		{
			title: 'Base Map',
			id: 'base_map',
			itemIds: ['hillshade']
		},
		{
			title: 'Air Traffic Layers',
			id: 'air_traffic_layers',
			itemIds: ['notify-and-fly']
		},
		{
			title: 'National Airspace',
			id: 'national_airspace',
			itemIds: [
				'faa_class_airspace',
				'faa_tfrs',
				'faa_sua',
				'faa_nsufrs',
				'faa_nsufrs_pt',
				'faa_airspace_boundaries',
				'faa_airports',
				'faa_recreational_flyer_sites',
				'faa_identification_areas',
				'faa_military_training_routes'
			]
		},
		{
			title: 'Local Advisories',
			id: 'local_advisories',
			itemIds: ['geoportal_advisories', 'va_fix', 'faa_stadiums', 'national_parks']
		}
	];

	const sections = sectionsLayout.map(s => {
		return {
			...s,
			items: s.itemIds.flatMap(id =>
				allItems.filter(i => {
					return i.id === id;
				})
			)
		};
	});
	return sections;
};
export const getSelectedMapLayerList = ({ allLayers, currentSettings }) => {
	return allLayers.filter(layer => currentSettings[layer?.id]);
};

export const updateLayerSettings = ({ layerToggleList, currentSettings }) => {
	const updatedSettings = { ...currentSettings };
	if (layerToggleList.length > 0) {
		layerToggleList.forEach(layer => {
			const hasSetting = Object.hasOwnProperty.call(currentSettings, layer.id);
			if (!hasSetting) {
				updatedSettings[layer.id] = true;
			}
		});
	}
	return updatedSettings;
};
