import { Mapbox, notifyAndFlyLayerId, airspaceClassLayerName } from 'constants/mapConstants';
import { useEffect } from 'react';
import { fetchMapLayers } from 'actions/map';
import { useDispatch, useSelector } from 'react-redux';
import {
	mapSourcesSelector,
	mapStyleSelector,
	visibleMapLayersSelector,
	mapLoadingSelector,
	mapStyleOptionsSelector
} from 'slice-reducers/mapSlice';
import { Layer, Source } from 'react-mapbox-gl';
import MapLayerControls from 'components/map/MapLayerControls';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const Map = ({
	map,
	children,
	id,
	layerPickerPosition,
	unauthenticated,
	styleLoadCallback,
	idle,
	setIdle,
	...rest
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const visibleMapLayers = useSelector(visibleMapLayersSelector);
	const selectedMapStyle = useSelector(mapStyleSelector);
	const mapStyleOptions = useSelector(mapStyleOptionsSelector);
	const selectedStyleUrl = mapStyleOptions.find(s => s.id === selectedMapStyle)?.mapbox_path;
	const mapStyle = selectedStyleUrl ? selectedStyleUrl : 'mapbox://styles/mapbox/streets-v11';
	const sources = useSelector(mapSourcesSelector);
	const mapLoading = useSelector(mapLoadingSelector);
	const disableMapLayerControls = !idle || mapLoading;
	const onNotifyRoute = location.pathname.includes('notify');
	const disabledLayerToggles = onNotifyRoute
		? [airspaceClassLayerName, notifyAndFlyLayerId]
		: [airspaceClassLayerName];

	const handleStyleLoad = map => {
		if (styleLoadCallback) {
			styleLoadCallback(map);
		}
	};

	const mapLayerChangeCallback = () => {
		//Disables the map layer picker until the previous change is complete
		setIdle(false);
	};

	useEffect(() => {
		dispatch(
			fetchMapLayers({
				region: 'us'
			})
		);
	}, [mapStyle, dispatch]);

	return (
		<>
			<Mapbox {...rest} style={mapStyle} onStyleLoad={handleStyleLoad}>
				{sources.map(s => {
					if (s.type === 'geojson') {
						return (
							<Source
								key={s.id}
								id={s.id}
								geoJsonSource={{
									type: s.type,
									data: s.data
								}}
							/>
						);
					} else {
						return (
							<Source
								key={s.id}
								id={s.id}
								tileJsonSource={{
									type: s.type,
									url: s.url
								}}
							/>
						);
					}
				})}
				{visibleMapLayers.map(l => {
					const layerSourceType = sources.find(s => s.id === l.source)?.type;
					if (layerSourceType === 'geojson') {
						return (
							<Layer
								key={l.id}
								sourceId={l.source}
								type={l.type}
								paint={l.paint}
								maxZoom={l.maxzoom}
								minZoom={l.minzoom}
								layout={l.layout}
							/>
						);
					} else {
						return (
							<Layer
								key={l.id}
								sourceId={l.source}
								sourceLayer={l['source-layer']}
								type={l.type}
								paint={l.paint}
								maxZoom={l.maxzoom}
								minZoom={l.minzoom}
								layout={l.layout}
							/>
						);
					}
				})}
				{children}
			</Mapbox>
			<MapLayerControls
				disabled={disableMapLayerControls}
				onChangeCallback={mapLayerChangeCallback}
				disabledToggles={disabledLayerToggles}
			/>
		</>
	);
};

//This component also expects the props that react-mapbox-gl needs (see library documentation).

Map.propTypes = {
	showLayerPicker: PropTypes.bool,
	show3DToggle: PropTypes.bool,
	disabledToggles: PropTypes.arrayOf(PropTypes.string),
	idle: PropTypes.bool.isRequired,
	setIdle: PropTypes.func.isRequired
};

export default Map;
