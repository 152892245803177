/* eslint-disable react/style-prop-object */
import { useEffect, useState } from 'react';
import { Marker } from 'react-mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import {
	drawInstanceSelector,
	dropMapPin,
	mapPinCoordinatesSelector,
	refreshNotifyAndFlyLayerSelector,
	setNotifyArea,
	setRadius,
	setRefreshNotifyAndFlyLayer,
	notifyAreaSelector
} from 'slice-reducers/mapSlice';
import { setPlaceDetails } from 'slice-reducers/locationAndAdvisoriesSlice';
import { defaultMapCenter } from 'constants/mapConstants';
import { userCoordinatesSelector } from 'slice-reducers/appSlice';
import SideMenu from 'components/navigation/SideMenu';
import { useNavigate, useLocation } from 'react-router-dom';
import { logEvent } from 'actions/firebase';
import { PIN_DROP_TAP } from 'constants/firebase';
import { addCoordinatesToUrl, getQueryParamsFromLocation } from 'actions/url';
import mapPin from 'assets/images/mapPin.png';
import { addMapControls, checkInsideMapBounds } from 'actions/mapBoxAndTurf';
import { notifyAndFlyCreateEventName, pusherPublicChannelName } from 'constants/pusherConstants';
import { pusher } from 'services/pusher';
import { createErrorAlert } from 'utils/generalUtils';
import {
	fetchAndSetAdvisories,
	fetchAndSetUserLocation,
	fetchNotifyLayerAndAdvisories
} from 'actions/map';
import { PUSHER_APP_ENV } from 'constants/envVars';
import { addAlert } from 'actions/alerts';
import center from '@turf/center';
import { convertFeetToMiles } from 'utils/mapUtils';
import circle from '@turf/circle';
import { featureCollection } from '@turf/helpers';
import Map from 'components/map/Map';
import { Box } from '@mui/material';
const styles = {
	mapContainer: {
		height: '100%',
		width: '100%'
	},
	mapPin: {
		position: 'relative',
		top: '20px'
	}
};

const MapContainer = ({ widthOffset }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const mapPinCoordinates = useSelector(mapPinCoordinatesSelector);
	const userCoordinates = useSelector(userCoordinatesSelector);
	const notifyArea = useSelector(notifyAreaSelector);
	const [map, setMap] = useState();
	const [idle, setIdle] = useState(false);
	const [previousPathname, setPreviousPathname] = useState(null);
	const [notifyPath, setNotifyPath] = useState(false);

	const queryParams = getQueryParamsFromLocation(location);
	const initialMapCenter =
		queryParams.lat && queryParams.long ? [queryParams.long, queryParams.lat] : defaultMapCenter;
	const [mapCenter, setMapCenter] = useState(initialMapCenter);
	const [mapZoom, setMapZoom] = useState(initialMapCenter === defaultMapCenter ? 4 : 13);
	const [needsRealtimeUpdate, setNeedsRealtimeUpdate] = useState(false);
	const [notifyChannelSubscribed, setNotifyChannelSubscribed] = useState(false);
	const [subscribing, setSubscribing] = useState(false);
	const refreshNotifyAndFlyLayer = useSelector(refreshNotifyAndFlyLayerSelector);
	const drawObject = useSelector(drawInstanceSelector);

	const mapClick = async (map, evt) => {
		const long = evt.lngLat.lng;
		const lat = evt.lngLat.lat;
		logEvent(PIN_DROP_TAP);
		addCoordinatesToUrl({ lat, long, navigate, pathname: '/' });
		//Clear place details
		dispatch(setPlaceDetails(null));
	};

	const handleUpdateRadius = fc => {
		const radius = fc.features[0].properties.radiusInFeet;
		let updatedRadiusInFeet;
		if (radius > 8000) {
			// max value on radius
			updatedRadiusInFeet = 8000;
		} else if (radius < 600) {
			// min value on radius
			updatedRadiusInFeet = 600;
		} else {
			updatedRadiusInFeet = radius;
		}
		const currentCenter = fc.features[0].properties.center;
		const radiusInMiles = convertFeetToMiles(updatedRadiusInFeet);
		const updatedWithNewRadius = circle(currentCenter, radiusInMiles, {
			units: 'miles',
			properties: { isCircle: true, center: currentCenter, radiusInFeet: updatedRadiusInFeet }
		});
		drawObject.set(featureCollection([updatedWithNewRadius]));
		dispatch(setRadius(updatedRadiusInFeet));
	};

	const updateFeatures = (e, drawObj, map) => {
		const updated = e;
		handleUpdateRadius(updated);
		const all = drawObj.getAll();
		// set lat long in query params to center of feature circle
		const featureCenter = center(all);
		const long = featureCenter.geometry.coordinates[0];
		const lat = featureCenter.geometry.coordinates[1];
		addCoordinatesToUrl({ lat, long, navigate, pathname: '/notify' });
		// set updated feature as notify area
		dispatch(setNotifyArea(all));
	};

	const onStyleLoad = map => {
		addMapControls(map);
		map.addControl(drawObject, 'top-right');
		map.on('draw.update', e => updateFeatures(e, drawObject, map));
		setMap(map);
		map.on('idle', () => {
			setIdle(true);
		});
	};

	const onZoom = map => {
		setMapZoom(map.getZoom());
	};

	useEffect(() => {
		if (map) {
			map.resize();
		}
	}, [widthOffset, map]);

	useEffect(() => {
		if (!userCoordinates) {
			dispatch(
				fetchAndSetUserLocation({
					navigate,
					lat: queryParams.lat,
					long: queryParams.long,
					location
				})
			);
		}
	}, [dispatch, userCoordinates, queryParams.lat, queryParams.long, navigate, location]);

	useEffect(() => {
		if (refreshNotifyAndFlyLayer) {
			//Refresh notify & fly layer and advisories after user submits their flight area
			dispatch(
				fetchNotifyLayerAndAdvisories({
					map,
					lat: queryParams.lat,
					long: queryParams.long,
					showLoader: !needsRealtimeUpdate //Hide loader and update silently for realtime updates
				})
			);
			setNeedsRealtimeUpdate(false);
			dispatch(setRefreshNotifyAndFlyLayer(false));
		}
	}, [
		refreshNotifyAndFlyLayer,
		dispatch,
		map,
		queryParams.lat,
		queryParams.long,
		needsRealtimeUpdate
	]);

	useEffect(() => {
		if (mapPinCoordinates.lat && mapPinCoordinates.long) {
			dispatch(fetchAndSetAdvisories({ lat: mapPinCoordinates.lat, long: mapPinCoordinates.long }));
		}
	}, [mapPinCoordinates, dispatch]);

	useEffect(() => {
		if (queryParams.lat && queryParams.long) {
			//Place map pin at query param lat & long
			dispatch(dropMapPin({ lat: queryParams.lat, long: queryParams.long }));
		}
	}, [queryParams.lat, queryParams.long, dispatch]);

	useEffect(() => {
		const currentPathname = location.pathname;
		if (currentPathname === '/notify') setNotifyPath(true);
		if (currentPathname === '/') setNotifyPath(false);
	}, [location.pathname]);

	useEffect(() => {
		if (mapZoom === 4 && queryParams.lat && queryParams.long) {
			//Zoom in to selected location
			setMapCenter([queryParams.long, queryParams.lat]);
			setMapZoom(13);
		}
	}, [queryParams.lat, queryParams.long, mapZoom]);

	useEffect(() => {
		if (map) {
			const pinVisible = checkInsideMapBounds({
				lat: queryParams.lat,
				long: queryParams.long,
				map
			});
			if (!pinVisible && queryParams.long && queryParams.lat) {
				//Center map on new pin if not already visible
				setMapCenter([queryParams.long, queryParams.lat]);
				setMapZoom(13);
			}
		}
	}, [map, queryParams.lat, queryParams.long]);

	useEffect(() => {
		if (!notifyChannelSubscribed && map && !subscribing) {
			setSubscribing(true);
			const channel = pusher.subscribe(pusherPublicChannelName);
			channel.bind('pusher:subscription_error', error => {
				const alert = createErrorAlert({
					message: `There was a problem subscribing to channel: ${pusherPublicChannelName}`
				});
				dispatch(addAlert(alert));
			});
			channel.bind('pusher:subscription_succeeded', () => {
				setNotifyChannelSubscribed(true);
				channel.bind(notifyAndFlyCreateEventName, data => {
					const messageEnv = data.meta.environment_name;
					//Do not react to messages from other environments
					if (messageEnv === PUSHER_APP_ENV) {
						// Use these state changes to trigger a re-fetch of the map and advisories
						// You can not directly call the api from here because query parameters (lat & long)
						// will not be updated correctly because this listener is not updated
						// by changes in the React lifecycle
						setNeedsRealtimeUpdate(true);
						dispatch(setRefreshNotifyAndFlyLayer(true));
					}
				});
			});
		}
	}, [notifyChannelSubscribed, map, subscribing, dispatch]);

	useEffect(() => {
		// Set notify area as draw object
		if (map && drawObject && notifyArea) {
			drawObject.set(notifyArea);
			if (Object.hasOwnProperty.call(notifyArea.features[0], 'id')) {
				drawObject.changeMode('direct_select', {
					featureId: notifyArea.features[0].id
				});
			} else {
				drawObject.changeMode('simple_select');
			}
		} else if (map && drawObject) {
			drawObject.deleteAll();
		}
	}, [drawObject, map, notifyArea]);

	// -- Future: Implement idle behavior if we need to save on Pusher messages --

	// useEffect(() => {
	// 	console.log(idle ? 'IDLE' : 'ACTIVE', 'subscribed', notifyChannelSubscribed);
	// 	if (!idle && !notifyChannelSubscribed) {
	//
	// 		const channel = pusher.subscribe(notifyAndFlyChannelName);
	// 		setNotifyAndFlyChannel(channel);
	// 		channel.bind(newFlightEvent, data => {
	// 			//fetch new data here
	// 			console.log('NEW FLIGHT!!!', data.message);
	// 		});
	//
	// 		channel.bind('pusher:subscription_succeeded', () => {
	// 			setNotifyChannelSubscribed(true);
	// 		});
	// 		channel.bind('pusher:subscription_error', error => {
	// 			console.log('! ERROR');
	// 		});
	//
	// 	} else if (idle && notifyChannelSubscribed) {
	// 		notifyAndFlyChannel.unsubscribe();
	// 		setNotifyChannelSubscribed(false);
	// 	}
	// }, [idle, notifyChannelSubscribed, notifyAndFlyChannel]);

	// useEffect(() => {
	// 	if (!idle) {
	// 		//fetch map layers and advisories when user becomes active
	// 		console.log('GET MAP LAYERS AND ADVISORIES');
	// 	}
	// }, [idle]);

	// ----

	useEffect(() => {
		const currentPathname = location.pathname;
		if (currentPathname === '/notify' && previousPathname !== '/notify') {
			//User coming from "/" into "/notify"
			//Center map on notify area and zoom in
			//This also prevents map from re-centering on every click while on "/notify"
			setMapZoom(13);
			setMapCenter([queryParams.long, queryParams.lat]);
		}
		setPreviousPathname(currentPathname);
	}, [location.pathname, map, notifyArea, queryParams.lat, queryParams.long, previousPathname]);

	const coordinatesDefined = mapPinCoordinates.long !== '' && mapPinCoordinates.lat !== '';
	const coordinates = [Number(mapPinCoordinates.long), Number(mapPinCoordinates?.lat)];

	return (
		<Box sx={styles.mapContainer}>
			<SideMenu />
			<Map
				containerStyle={{
					height: '100%',
					width: '100%'
				}}
				zoom={[mapZoom]}
				center={mapCenter}
				onClick={!notifyPath && mapClick}
				movingMethod='jumpTo'
				styleLoadCallback={onStyleLoad}
				onZoom={onZoom}
				idle={idle}
				setIdle={setIdle}
				map={map}
			>
				{coordinatesDefined && (
					<Marker coordinates={coordinates} anchor='bottom'>
						<Box component='img' src={mapPin} alt={'map-pin'} sx={styles.mapPin} />
					</Marker>
				)}
			</Map>
		</Box>
	);
};

export default MapContainer;
